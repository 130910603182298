export default {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          "Click to tune": "クリックして調整",
          "or drag to move": "ドラッグして移動"
        }
      },
      inlineToolbar: {
        converter: {
          "Convert to": "変換"
        }
      },
      toolbar: {
        toolbox: {
          Add: "追加"
        }
      }
    },
    tools: {
      table: {
        "Add column to left": "左に列を挿入",
        "Add column to right": "右に列を挿入",
        "Delete column": "列を削除",
        "Add row above": "上に行を追加",
        "Add row below": "下に行を追加",
        "Delete row": "行を削除",
        "Heading": "列ヘッダー",

        "Without headings": "ヘッダー無し",
        "With headings": "ヘッダーあり",
      }
    },
    toolNames: {
      Text: "テキスト",
      Heading: "ヘッダー",
      List: "リスト",
      Checklist: "チェックリスト",
      Quote: "引用",
      Delimiter: "区切り線",
      Table: "表",
      Link: "埋め込みリンク",
      Bold: "太字",
      Italic: "斜体",
      Marker: "マーカー",
      Underline: "下線",
      InlineCode: "コード",
      Image: "画像",
      Alert: "強調",
      Warning: "警告"
    },
    blockTunes: {
      delete: {
        Delete: "削除"
      },
      moveUp: {
        "Move up": "上に移動"
      },
      moveDown: {
        "Move down": "下に移動"
      }
    }
  }
}