<template>
  <div>
    <div class="editor-js-container" ref="editorJsContainer" @dblclick="dblclick"/>
    <v-dialog v-model="imageDialog" :max-width="1200">
      <v-img :src="imageUrl" ></v-img>
    </v-dialog>
  </div>
</template>
<script>
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';

import Quote from '@editorjs/quote';
import CodeTool from '@editorjs/code';
import LinkTool from '@editorjs/link';
import LinkToolIframe from '@/custom/custom-editorjs-link-iframe';
import ImageTool from '@editorjs/image';
import Embed from '@editorjs/embed';

import NestedList from '@editorjs/nested-list';
import Checklist from '@editorjs/checklist';
import Table from '@editorjs/table';

import Warning from '@editorjs/warning';
import Alert from 'editorjs-alert';

import Marker from '@editorjs/marker';
import Underline from '@editorjs/underline';
import InlineCode from '@editorjs/inline-code';
import Delimiter from '@editorjs/delimiter';

// import DragDrop from 'editorjs-drag-drop';
import DragDrop from '@/custom/custom-editorjs-drag-drop';
import Undo from 'editorjs-undo';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';

import ja from '@/i18n/editorjs-ja.js'
import {RepositoryFactory} from '../../../api/RepositoryFactory'

export default {
  props: {
    projectId: {
      type: Number,
      require: false,
      default: 0,
    },
    data: Object,

    imageDialog: false,
    imageUrl: null,
    initReadonly: Boolean,
  },
  data() {
    return {
      editor: {},
      config: {
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
            config: {
              placeholder: 'ヘッダーを入力してください',
              levels: [1, 2, 3, 4],
              defaultLevel: 3
            },
            tunes: ['tuneorg'],
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            config: {
              placeholder: '文章を入力してください',
            },
            tunes: ['tuneorg'],
          },

          quote: {
            class: Quote,
            inlineToolbar: true,
            config: {
              quotePlaceholder: '引用を記入',
              captionPlaceholder: '引用元',
            },
          },
          code: CodeTool,
          linkTool: {
            class: LinkTool,
            config: {
              endpoint: `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_VERSION}/ogp/simple`,
            }
          },
          linkToolIframe: LinkToolIframe,
          image: {
            class: ImageTool,
            config: {
              uploader: {
                uploadByFile: this.uploadByFile
              },
              captionPlaceholder: 'イメージの説明を記入'
            }
          },
          embed: Embed,
          delimiter: Delimiter,

          list: {
            class: NestedList,
            inlineToolbar: true,
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true,
          },

          table: {
            class: Table,
            inlineToolbar: true,
          },

          warning: Warning,
          alert: Alert,

          marker: Marker,
          underline: Underline,
          inlineCode: InlineCode,

          tuneorg: {
            class:AlignmentTuneTool,
          },
        },

      },
    }
  },
  methods: {
    dblclick(e) {
      if(e.target && e.target.tagName == 'IMG' &&  e.target.classList.contains('image-tool__image-picture') && e.target.src) {
        this.imageUrl = e.target.src;
        this.imageDialog = true;

      }

    },
    // Editor.js関連
    doEditor() {
      this.editor = new  EditorJS({
        holder: this.$refs.editorJsContainer,
        ...this.config,

        data: this.data,

        readOnly: this.initReadonly,

        logLevel: 'INFO',

        i18n: ja,

        onReady: () => {
          const undo = new Undo({editor: this.editor});
          undo.initialize(this.data);

          new DragDrop(this.editor);
        },
      })
    },
    uploadByFile(file) {
      const ImagesRepository = RepositoryFactory.get('images');
      console.log(this.projectId)
      return ImagesRepository.uploadNoteImage(file, this.projectId).then(
        response => {
          if(response.data.result) {
            return {
              success: true,
              file: {
                url: response.data.data.imageUrl
              }
            }
          } else {
            return {
              success: false
            }
          }
        }
      );
    },

    async save() {
      return this.editor.save();
    },

    toggleReadOnly(){
      this.editor.readOnly.toggle();
    }
  },
  mounted() {
    this.doEditor()
  },
}
</script>
<style lang="scss" scoped>
.editor-js-container ::v-deep {
  .cdx-input.cdx-quote__text {
    border-top: none;
    border-right: none;
    border-bottom: none;
    box-shadow: none;
    border-radius: 0;
    border-left: 3px solid rgb(55, 53, 47);
    min-height: 70px;
    margin-bottom: 0;
  }
  .cdx-input.cdx-quote__caption {
    border: none;
    box-shadow: none;
    padding-top: 0;
  }
  
  .cdx-input.image-tool__caption {
    border: none;
    box-shadow: none;
    padding-top: 0;
    font-size: 12px;
  }

  .tc-table.tc-table--heading {
    .tc-row:first-child {
      background-color: aliceblue;
    }
  }

  code.inline-code {
    background-color: rgba(250, 239, 240, 0.78);
    color: #b44437;
    padding: 3px 4px;
    border-radius: 5px;
    font-size: 0.86em;
    font-weight: 500;
  }

  .ce-delimiter {
    height: 30px;

    &::before {
      content: "";
      height: 0;
      position: absolute;
      left: 0;
      top: 13px;
      width: 100%;
      border: 1px solid gray;
    }
  }

  .ce-block__content, .ce-toolbar__content {
    max-width: 900px;
  }

  .ce-block [contenteditable=false] {
    word-break: break-all;
  }
}
</style>