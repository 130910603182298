<template>
  <div id="reference-parts-sidebar-wrap" class="d-flex" :style="style">
    <div id="reference-parts-sidebar" :style="!open ? {borderRight: 'none'} : {}">
      <div class="header py-2" v-show="open">
        <v-chip-group
          v-if="sections"
          column
          class="parts-chips justify-center mx-4"
          active-class="indigo darken-1"
        >
          <div v-for="section in sections" :key="section.sectionId">
            <v-chip
              :ref="`chip_${section.dataRef}`"
              color="#7D9BCC"
              class="white--text"
              @click="toggleSection(section)"
              v-if="$route.params.partsName !== section.dataRef"
            >
              {{ section.label }}
            </v-chip>
          </div>
        </v-chip-group>
      </div>
      <div class="parts-wrap px-8 py-4"  v-show="open">
        <div
          :class="'parts d-flex ' + (fullExpand ? 'justify-center' : 'justify-start')"
        >
          <section-resolver
            v-if="dataInit && currentSection"
            :elementId="currentSection.dataRef"
            :name="strategyPartsMetaDict[currentSection.dataRef].comp"
            :inputStatus="currentSection.inputStatus"
            :ref="currentSection.name"
            :hasWritePermission="false"
            :dataInit="dataInit"
            ref-mode
            :noteMode="noteMode"
            :ref-full-expand="fullExpand"
            v-model="partsData"
            @update="needUpdate = true"
          ></section-resolver>
        </div>
      </div>
    </div>
    <div>
      <!-- 閉じているとき -->
      <div v-if="!open" v-ripple class="side-area" @click.stop="openAndCloseArea">
        <v-icon color="white" class="pb-2">mdi-file-eye-outline</v-icon>
        <span>{{ ribbonTxt }}</span>
      </div>
      
      <!-- 開いているとき -->
      <div v-else class="side-area">
        <!-- 閉じる -->
        <div class="pb-2">
          <v-btn icon @click.stop="openAndCloseArea">
            <v-icon v-if="open" color="white">mdi-close</v-icon>
          </v-btn>
        </div>

        <hr class="divider">

        <div class="pt-2">
          <!-- 伸ばす -->
          <v-btn v-if="!fullExpand" icon @click.stop="onClickFullExpand">
            <v-icon color="white">mdi-arrow-expand-right</v-icon>
          </v-btn>
          <!-- 縮める -->
          <v-btn v-else icon @click.stop="onClickFullExpand">
            <v-icon color="white">mdi-arrow-expand-left</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import strategyPartsMetaDict, {
  StrategyPartsMetaDictItem,
} from "../../../utils/strategyPartsMetaDict";
import SectionResolver from "@/components/util/SectionResolver";

export default {
  components: {
    SectionResolver,
  },
  props: {
    noteMode: {
      type: Boolean, 
      default: false,
    },
    defaultParts: String,
    ribbonTxt: {
      type: String,
      default: "他パーツを参照する",
    }
  },
  data() {
    return {
      open: false,
      fullExpand: false,
      dataInit: false,
      currentSection: null,
      strategyPartsMetaDict,
      value: null,
      partsMeta: new StrategyPartsMetaDictItem(),
    };
  },
  computed: {
    ...mapGetters(["registeredSections", "hasWritePermission"]),
    style() {
      if (this.fullExpand & this.open) {
        return { width: "100%", minWidth: "none" };
      }
      if (this.open) {
        return { width: "600px", minWidth: "600px" };
      }
      return { width: 0, minWidth: 0 };
    },
    sections() {
      return this.registeredSections?.sections.map((section) => {
        return { ...section.section, inputStatus: section.inputStatus };
      });
    },

    partsData() {
      return this.partsMeta.inputDataKey ? this.value[this.partsMeta.inputDataKey] : this.value;
    },
  },

  async mounted() {
    // セクションの一覧を取得
    if (!this.registeredSections) {
      await this.getRegisteredSections();
    }

    this.showDefault();
  },

  methods: {
    ...mapActions(["getRegisteredSections"]),

    showDefault() {
      // デフォルトで表示するパーツが指定されている場合は表示する
      if(!this.defaultParts) return;
    
      let filtered = this.sections.filter(data => data.dataRef == this.defaultParts)
      if(!filtered.length) return
      
      this.toggleSection(filtered[0]);
      // チップの色を変える
      this.$refs[`chip_${filtered[0].dataRef}`][0].isActive = true;
      
    },

    onClickFullExpand() {
      this.fullExpand = !this.fullExpand;
      const val = this.fullExpand & this.open;
      this.$emit("click-expand", val);
      this.fireChangeStatus();
    },
    openAndCloseArea() {
      this.open = !this.open;
      this.fullExpand = false;
      const val = this.fullExpand & this.open;
      this.$emit("click-expand", val);
      this.fireChangeStatus();
    },

    fireChangeStatus() {
      this.$emit("change-status", (!this.open ? "close" : !this.fullExpand ? "open" : "expand") );
    },

    toggleSection(section) {
      if (!section) return;
      if (!this.currentSection) {
        this.currentSection = section;
      } else if(this.currentSection.sectionId !== section.sectionId) {
        this.currentSection = section;
      } else {
        this.currentSection = null;
      }
    }

  },
  watch: {
    async currentSection(to) {
      if (!to) return;
      this.dataInit = false;

      // メタ情報辞書から戦略パーツメタ情報を取得する
      this.partsMeta = strategyPartsMetaDict[to.dataRef];

      // データを取得する
      await this.$store.dispatch(this.partsMeta.fetchAction);
      this.value = this.$store.getters[this.partsMeta.getterKey];
      this.dataInit = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#reference-parts-sidebar-wrap {
  position: sticky;
  top: 0;
  height: calc(100vh - 125px);
  z-index: 100;
  transition: 300ms 0s ease;
  transition-property: width, min-width, max-width;
  #reference-parts-sidebar {
    max-width: calc(100% - 50px);
    min-width: calc(100% - 50px);
    background-color: #f1f7ff;
    border-left: solid 6px $bt-primary;
    border-right: solid 6px $bt-primary;
    display: flex;
    flex-direction: column;
    .header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 5;
      background-color: #f1f7ff;
      box-shadow: 0px 2px 2px 0px #bdbdbd;
    }
    .parts-wrap {
      width: 100%;
      height: 100%;
      overflow-x: auto;
      overflow-y: auto;

      @include scrollAreaY();
      @include scrollAreaX();
    }
  }
  .side-area {
    display: flex;
    align-items: center;
    background-color: $bt-primary;
    color: white;
    width: 50px;
    height: fit-content;
    padding: 15px 10px;
    margin-top: 30px;
    margin-left: -6px; // ブラウザの拡大率にによっては左に余白が見えることがあったのでずらしておく
    border-radius: 0 15px 15px 0;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    cursor: pointer;
    .divider {
      width: 25px;
      background-color: #EEEEEE;
      border: solid 1px #EEEEEE;
      border-radius: 1px;
    }
  }
}
</style>

<style lang="scss">
#reference-parts-sidebar-wrap {
  .parts-chips {
    .v-slide-group__content {
      justify-content: center !important;
    }
  }
  .parts {
    .ref-mode {
      // 参照エリア見た目調整用
      .v-text-field--outlined {
        fieldset {
          // 外枠非表示
          border: none;
        }
        .v-label--active {
          // ラベルの位置調整
          color: black;
          transform: translateY(-40px) translateX(-12px) scale(0.75);
        }
        .v-label--active::before {
          // ラベル行頭に「・」表示
          content: "・";
        }
        .v-input__slot {
          // margin-top: 20px;
          background-color: #eceff1 !important;
        }
        .v-input__append-inner {
          // セレクトボックスの▼を非表示
          display: none;
        }
      }
    }
    .label-outside {
      .v-text-field--outlined {
        .v-input__slot {
          margin-top: 0 !important;
        }
      }
    }
  }
}
</style>