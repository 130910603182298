<template>
  <!-- 振り返り完了確認 -->
  <v-dialog :value="value" @input="$emit('input', $event)" :width="600" v-bind="$attrs">
    <v-card class="py-0 px-3">
      <v-card-title>
        振り返りを完了する
      </v-card-title>
      <v-card-text class="text-subtitle-2">
        <div class="msg mb-2">
          アーカイブ名を入力してください
        </div>
        <div>
          <v-text-field
            label="アーカイブ名"
            v-model="archiveName"
            dense
            outlined
          />
        </div>
        <div class="red--text mb-4 text-center">
          振り返りを完了すると、振り返り情報は編集できなくなります。<br>
          振り返りを完了しますか？
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2 mb-3"
          depressed
          text
          min-width="190"
          @click="$emit('input', false)"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="btAccent"
          class="white--text mx-2 mb-3"
          depressed
          min-width="190"
          @click="completeEvaluation()"
        >
          完了する
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { RepositoryFactory } from "../../../api/RepositoryFactory";

export default {
  props: {
    value: Boolean,
  },

  data() {
    return {
      archiveName: "",
    }
  },

  computed: {
    ...mapGetters(["project", "userInfo", "registeredSections", "hasWritePermission"]),

  },

  methods: {
    ...mapActions(["getRegisteredSections"]),

    async completeEvaluation() {
      // プロジェクトを複製して、アーカイブする。
      await this.getRegisteredSections();
      const body = {
        project: {
          projectName: this.archiveName,
        },
        sections: this.registeredSections.sections.map(item => item.section.sectionId),
        parentId: this.project.id,
        userId: this.userInfo.userId,
      };

      const repo = RepositoryFactory.get("projectCopy");
      const response = await repo.copyProject(this.project.id, body);
      if (response.data.result && response.data.resultCode === "0000") {
        console.log("copyProject response:", response);
      } else {
        this._sendError(response.data.message);
      }

      this.$store.commit('setToast', {msg: '振り返りを完了しました。', color: 'green'});

      // 過去ログへ移動する。
      this.$router.push({name: "ProjectLog"});
    },

    setArchivename() {
      this.archiveName = this.project.projectName;
    },
  },

  created() {
    this.setArchivename();
  }
}
</script>

<style lang="scss" scoped>
.msg {
  color: rgba(0, 0, 0, 0.87);
}
</style>