<template>
  <v-btn v-bind="$attrs" v-on="$listeners">
    <v-icon v-if="icon" class="me-1">{{ icon }}</v-icon>
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  props: {
    label: String,
    icon: String,
    fgColor: String,
  }
}
</script>